





import {Component, Prop, Vue} from 'vue-property-decorator';
@Component({})
export default class ProgressBar extends Vue {
  @Prop({type: Number, default: 0}) readonly percents!: number;
}
