

































































































import {Component, Mixins, Watch} from 'vue-property-decorator';
import DropDown from '@/components/DropDown.vue';
import {vxm} from '@/store';
import moment from 'moment';
import DebounceMixin from '@/mixins/DebounceMixin';
import Loader from '@/components/Loader.vue';
import Paginate from 'vuejs-paginate';
import {ModelDataInterface} from '@/types/userDataInterface';
import CreateAlbum from '@/components/modals/CreateAlbum.vue';
import {PhotoInterface} from '@/types/photoInterface';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

@Component({
  components: {ConfirmationModal, CreateAlbum, DropDown, Loader, Paginate},
})
export default class ModelAlbums extends Mixins(DebounceMixin) {
  isMobile = false;
  query = '';
  totalItems = 0;
  params: any = {
    page: 1,
    take: 4,
    keyword: '',
    performerId: vxm.user.data._id,
    isForSale: true,
  };
  orders = {
    newest: 'Recently added',
    mostPopular: 'Most Popular',
  };
  albumForDelete: PhotoInterface | null = null;
  loading = false;
  debouncedSearch = this.debounce(this.search, 700);
  showCreateAlbum = false;

  get albums(): PhotoInterface[] {
    return vxm.model.albumsList;
  }

  @Watch('query') onQueryChange() {
    this.debouncedSearch();
  }

  @Watch('params', {deep: true}) onParamsUpd() {
    const storage = JSON.parse(localStorage.getItem('model') as string);
    this.urlUpdate();
    if (
      storage &&
      storage[this.$route.name as string] &&
      JSON.stringify(this.$route.query) === JSON.stringify(storage[this.$route.name as string].params)
    ) {
      vxm.model.setAlbumsList(storage[this.$route.name as string]);
      this.totalItems = storage[this.$route.name as string].count;
      this.getContent(true);
    } else {
      this.getContent();
    }
  }

  get user(): ModelDataInterface {
    return vxm.user.data;
  }

  get pagesCount(): number {
    return Math.ceil(this.totalItems / this.params.take);
  }

  showSearchBlock() {
    this.$nextTick(() => {
      (this.$refs.searchInput as HTMLInputElement).focus();
    });
  }

  search() {
    this.params.page = 1;
    this.params.keyword = this.query;
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.params.sort = 'newest';
    if (this.$route.query) {
      const urlParams = JSON.parse(JSON.stringify(this.$route.query));
      if (urlParams.page) {
        urlParams.page = parseInt(urlParams.page, 10);
      }
      this.params = {...this.params, ...urlParams};
      if (this.params.keyword) {
        this.query = this.params.keyword;
      }
    }
  }

  getContent(backgroundUpdate = false) {
    this.loading = !backgroundUpdate;
    const params = JSON.parse(JSON.stringify(this.params));
    Object.keys(params).forEach((key) => {
      if (!params[key] && typeof params[key] !== 'boolean') {
        delete params[key];
      }
    });
    vxm.model
      .getMyAlbums(params)
      .then((res) => {
        this.totalItems = res.data.count;
        let storage = JSON.parse(localStorage.getItem('model') as string);
        if (!storage) {
          storage = {};
          storage[this.$route.name as string] = {};
        }
        storage[this.$route.name as string] = {
          params: this.$route.query,
          items: res.data.items,
          count: this.totalItems,
        };
        localStorage.setItem('model', JSON.stringify(storage));
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.loading = false;
      });
  }

  destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    this.isMobile = window.innerWidth <= 823;
  }

  formatDate(date): string {
    return moment(date).format('MMM DD YYYY @ HH.mm');
  }

  urlUpdate() {
    const params = JSON.parse(JSON.stringify(this.params));
    Object.keys(params).forEach((key) => {
      if (typeof params[key] !== 'boolean' && !params[key]) {
        delete params[key];
      }
      if (typeof params[key] === 'boolean') {
        params[key] = params[key] + '';
      }
    });
    delete params.take;
    delete params.performerId;
    params.page += '';
    if (JSON.stringify(params) !== JSON.stringify(this.$route.query)) {
      this.$router.replace({query: params});
    }
  }

  changeSort(key: string) {
    this.params.page = 1;
    this.params.sort = key;
  }

  deleteAlbum(confirmed?: boolean) {
    if (confirmed && this.albumForDelete) {
      vxm.model
        .deletePhoto(this.albumForDelete._id)
        .then(() => {
          this.getContent();
        })
        .catch((error) => {
          return error;
        });
    }
    this.albumForDelete = null;
  }
}
